.app-footer {
    background-color: #013a63; /* Example background color */
    color: white;
    text-align: center; /* Center text horizontally */
    padding: 5px; /* Reduced padding */
    min-height: 40px; /* Minimum height for the footer */
}

.app-footer p {
    margin: 0; /* Remove default margins */
    line-height: 40px; /* Vertically center text if footer is 40px high */
    text-align: center; /* Ensure text is also centered within its own block */
}
