.chatbots-search {
    width: 100%;
    background-color: #013a63; /* Match ChatWindow background color */
    position: relative; /* For positioning the search results */
    padding: 0;
    margin: 0;
  }
  
  .search-input {
    width: 100%;
    background-color: #fff; /* White input field */
    border: none;
    padding: 10px;
    border-radius: 0; /* Optional: to make it flush with the container */
  }
  
  .search-results {
    background-color: #013a63; /* Match ChatWindow background */
    border: none;
    z-index: 10; /* Ensure it appears on top of the ChatWindow */
    overflow-y: auto;
    max-height: 300px; /* Optional: Add a height limit for the results */
  }
  