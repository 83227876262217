/* Header.css */

.custom-navbar {
    background-color: var(--primary-color) !important;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    height: 60px; /* Set your desired navbar height */
    display: flex;
    align-items: center; /* Vertically centers content inside the navbar */
  }
  
  .custom-navbar .navbar-nav {
    align-items: center; /* Vertically centers nav items */
  }
  
  .custom-navbar .navbar-brand,
  .custom-navbar .nav-link {
    margin: 0;
    padding: 0 15px; /* Adjust horizontal padding as needed */
    color: var(--text-color) !important;
  }
  
  .custom-navbar .nav-link:hover {
    color: var(--accent-color) !important;
  }
  
  /* Styling for the Logout Button */
  .custom-navbar .logout-button {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    padding: 5px 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .custom-navbar .logout-button:hover {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
    transform: scale(1.05);
  }
  
  /* Ensure the Login button is vertically centered */
  .custom-navbar .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Fix for the login button background color */
  .login-button {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  
.navbar-brand {
    font-size: 1.5rem; /* Adjust font size as needed */
    font-weight: bold;
  }
  
  /* Media query for small screens */
  @media (max-width: 767px) {
    .custom-navbar .navbar-brand,
    .custom-navbar .nav-link {
      padding: 0 10px; /* Adjust horizontal padding for smaller screens */
    }
  
    .custom-navbar .logout-button {
      padding: 5px; /* Adjust padding for smaller screens */
    }
  }