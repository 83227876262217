/* src/components/chat/MessageList.css */
.message-item {
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .bot-message {
    background-color: lightblue;
    color: #0c5460;
  }
  
  .user-message {
    background-color: lightgreen;
    color: #155724;
    text-align: right;
  }
  