/* Root Variables */
:root {
  --primary-color: #013a63; /* Deep marine blue */
  --secondary-color: #025e8c; /* Lighter marine blue */
  --accent-color: #ff6f61; /* Bright coral */
  --text-color: white;
  --font-family: 'Arial, sans-serif';
  --font-size-base: 16px;
  --font-size-heading: 24px;
}

/* Global Styles */
body {
  background-color: #f1f1f1; /* Change to a more neutral color */
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  margin: 0;
  padding: 0;
  line-height: 1.5;  /* Improves readability */
}

body, p, ul, li, h1, h2, h3, h4, h5, h6 {
  text-align: left; /* Default to left-align text */
}

/* Ensure the entire app fills the height of the viewport */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Ensure the App component takes up the full viewport */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the app takes the full viewport height */
}

/* Main content should grow and fill the space between header and footer */
.App-content {
  flex-grow: 1; /* Fills available space between header and footer */
  display: flex;
  flex-direction: column;
  justify-content: stretch; /* Ensures child components stretch */
}

/* Footer */
footer {
  background-color: #013a63;
  color: white;
  text-align: center;
  padding: 5px; /* Adjust padding as needed */
  min-height: 40px;
}

/* Card Component, Create, and Chat Columns */
.card,
.create-column,
.chat-column {
  background-color: none; /* Set to none explicitly to prevent the body color from affecting it */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin: 10px; /* Add margin to ensure spacing from the sides of the container */
}

/* Search Component Styling */
.search-container {
  background-color: #ffffff; /* White background to contrast with body */
  padding: 15px;
  border-radius: 10px;
  margin: 10px 20px; /* Consistent margins for alignment with other components */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input Focus */
input:focus,
textarea:focus,
select:focus {
  border-color: var(--accent-color); /* Bright Light Coral */
  box-shadow: 0 0 5px rgba(255, 76, 76, 0.5); /* Coral shadow on focus */
  outline: none;
}

/* Button Styles */
button {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--text-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 15px; /* Space between form elements */
}

button:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  transform: scale(1.05);  /* Slightly enlarges the button on hover */
}

/* Gradient Button */
.gradient-button {
  background: linear-gradient(45deg, var(--primary-color), var(--accent-color));
  color: var(--text-color);
}

/* Links */
a {
  color: var(--accent-color); /* Light Coral */
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .container {
    padding: 10px; /* Adjusted padding for mobile screens */
  }

  .card,
  .create-column,
  .chat-column {
    margin: 5px; /* Reduce margin on smaller screens for better use of space */
  }
}

/* Keyframes for Animation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom Select Styling */
.custom-select {
  background-color: var(--secondary-color); /* Lighter marine blue */
  color: var(--text-color); /* White text color to match the site theme */
  border: 1px solid var(--secondary-color);
  width: 100%;  /* Makes select box responsive */
  margin-bottom: 15px; /* Space between form elements */
}

.custom-select option {
  background-color: var(--secondary-color); /* Match dropdown options with overall theme */
  color: var(--text-color);
}

.custom-select:focus {
  border-color: var(--accent-color);
  box-shadow: 0 0 5px rgba(255, 76, 76, 0.5);
  outline: none;
}

/* Password Page */
.password-page-bg {
  background-color: var(--secondary-color);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 10px;
}

/* Headings and Labels */
h1,
.create-page-container h1 {
  font-size: var(--font-size-heading);
  font-weight: bold;
  color: var(--accent-color);
}

.form-label {
  font-weight: 600;  /* Semi-bold labels for emphasis */
}

/* Additional Scrollable Content Padding */
.scrollable-content {
  padding: 2rem;  /* Adjusts based on root font size */
}
