/* src/components/chat/AssistantsSidebar.css */

/* Limit width and add padding to ensure content does not spill */
.assistants-sidebar-container {
    max-width: 100%; /* Ensure it doesn't exceed parent container */
    overflow: hidden; /* Hide overflowing content */
    padding-left: 15px; 
    padding-right: 15px;
  
    width: 250px;
    overflow-y: auto;
    background-color: #025e8c;
    border-right: 1px solid #dee2e6;
    padding: 10px;
  
    /* New height properties */
    flex-grow: 1; /* Makes the container grow to fill its parent column */
    height: 100%; /* Ensures it takes up 100% of the available height */
  }
  

/* Ensure the text is left-aligned */
.assistants-sidebar-container .assistant-list-item {
  text-align: left;
}

.assistants-sidebar-container h5 {
    color: #013a63;
    text-align: center;
    width: 100%; /* Ensures h5 fills the container's width */
    display: block; /* Ensures it's treated as a block-level element */
  }

/* Add responsive behavior for different screen sizes */
@media (min-width: 768px) {
  .assistants-sidebar-container {
    max-width: 500px; /* Adjust the width as needed for larger screens */
  }
}

@media (max-width: 767px) {
  .assistants-sidebar-container {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.assistant-list-item {
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
    background-color: var(--accent-color) !important;
  }
  
.assistant-list-title {
    color: white !important;
  } 