/* src/components/chat/Chat.css */

/* Overall chat container to fill the available viewport minus navbar and footer */
.chat-container {
    height: calc(100vh - 56px - 56px); /* Adjust based on Navbar and Footer height */
    display: flex;
    flex-direction: column;
  }
  
  /* Assistants Sidebar */
  .assistants-sidebar {
    height: 100%;
    overflow-y: auto;
    background-color: #f8f9fa;
    border-right: 1px solid #dee2e6;
    padding: 10px;
    flex-grow: 1
  }
  
  .assistants-sidebar h5 {
    color: #013a63;
    text-align: center;
  }
  
  /* Chatbots Search Bar */
  .chatbots-search {
    padding: 10px;
    background-color: #ffffff;
    border-bottom: 1px solid #dee2e6;
  }
  
  /* Chat Window */
  .chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  /* Chat Header */
  .chat-header {
    background-color: #013a63;
    color: white;
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #dee2e6;
  }
  
  /* Chat Body */
  .chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f1f1f1;
  }
  
  /* No Assistant Selected Message */
  .no-assistant {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    color: #6c757d;
  }
  
  /* Chat Input Container */
  .chat-input-container {
    padding: 10px;
    border-top: 1px solid #dee2e6;
    background-color: #ffffff;
  }
  
  /* Footer */
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 56px; /* Adjust based on your footer's height */
  }
  
  /* Ensure body and html take the full viewport height */
html, body {
    height: 100%;
    margin: 0;
  }
  
  /* Main page container - ensures the full page height is used */
  .page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Fill the viewport height */
  }
  
  /* Header and footer are fixed heights */
  .header {
    background-color: #013a63; /* Example styling */
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footer {
    background-color: #013a63; /* Example styling */
    color: white;
    padding: 10px;
    text-align: center;
  }
  
  /* Content wrapper will grow to fill the available space between header and footer */
  .content-wrapper {
    flex-grow: 1; /* Allows the content to grow and fill the remaining space */
    display: flex;
    flex-direction: column;
  }
  
  /* Ensure that the chat container takes the full height of the content wrapper */
  .chat-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  
  /* For full height and proper flex alignment within columns */
  /* .chat-container .row {
    flex-grow: 1; 
  } */
  
  .chat-search-window-container {
    flex-grow: 1; /* Ensure the ChatWindow takes the available space */
    display: flex;
    flex-direction: column;
  }
  

  .chat-header {
    text-align: center;     /* Centers the text */
    color: #025e8c;             /* Sets the text color to red */
    background-color: white;
    padding: 15px;          /* Adds padding around the text */
    font-size: 2em;         /* Sets a larger font size */
    line-height: 1.2;       /* Adjusts line height for readability */
    margin: 5px 0;         /* Adds vertical margin */
    font-weight: bold;      /* Makes the text bold */
  }
  