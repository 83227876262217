/* Main content row styling to ensure proper flex display */
.main-content-row {
    display: flex;
    gap: 20px; /* Space between columns */
    margin: 20px; /* Margin around the main content row */
    align-items: stretch; /* Ensure both columns stretch to the same height */
    padding-left: 20px; /* Keep content from touching screen edges */
    padding-right: 20px;
    flex-grow: 1;
}

.main-content {
    margin-top: 0; /* Reset top margin */
    padding-top: 20px; /* Optional: Add padding if needed */
  }

/* Flex properties for create and chat columns with consistent styling */
.create-column,
.chat-column {
    flex: 1; /* Allow both columns to grow equally */
    overflow-y: auto; /* Allow scrolling if content exceeds available height */
    overflow-x: hidden;
    border: 1px solid var(--secondary-color); /* Subtle borders using secondary color */
    border-radius: 10px;
    padding: 15px;
    margin-left: 10px; /* Space between columns and container edges */
    margin-right: 10px;
    background: transparent; /* Remove background to use page's background */
    position: relative; /* Maintain z-index and control stacking */
    z-index: 2; /* Ensure create/chat columns appear above other elements */
    min-height: 0;
}

/* Scrollable content within the create and chat columns */
.scrollable-content {
    padding: 20px; /* Internal spacing */
    margin-bottom: 20px; /* Vertical spacing between components */
    position: relative; /* Set relative position to control stacking */
    z-index: 2; /* Ensure scrollable content appears above lower elements */
}

/* Ensure chat container takes full height */
.chat-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
}

/* Ensure row in chat container stretches */
/* .chat-container .row {
    flex-grow: 1;
    height: 100%;
} */

/* Ensure chat window stretches */
.chat-search-window-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Scrollbar styling */
.scrollable-content::-webkit-scrollbar {
    width: 8px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background-color: var(--accent-color);
    border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Padding for smaller screens */
.create-page-container {
    padding: 20px;
}

/* Custom Select Styling */
.custom-select {
    color: white;
    width: auto;
    max-width: 80%; /* Prevent spilling out of parent container */
    padding: 10px; /* Consistent padding */
    box-sizing: border-box; /* Include padding in element's width */
    position: relative; /* Correct positioning within the container */
    z-index: 1; /* Lower z-index to prevent overlap */
    justify-content: center;
    align-items: center;
}

/* Center align the Select Assistant form group */
.select-assistant-container {
    width: auto;
    max-width: 100%; /* Ensure it doesn't exceed the container width */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Ensure text is left-aligned in scrollable content */
.scrollable-content h1,
.scrollable-content p {
    text-align: left;
}

/* Media query for small screens */
@media (max-width: 767px) {
    .main-content-row {
        flex-direction: column; /* Stack columns vertically */
        height: auto; /* Allow columns to grow based on content */
    }

    .create-column,
    .chat-column {
        height: auto; /* Adjust height on small screens */
        margin-left: 0; /* Remove side margin for better stacking */
        margin-right: 0;
    }

    .search-container {
        margin: 10px; /* Adjust margin for smaller screens */
        padding-left: 20px; /* Consistent padding */
        padding-right: 20px;
    }

    h1 {
        font-size: 20px; /* Smaller heading for mobile */
    }

    button {
        padding: 8px 15px; /* Adjust button size */
    }
}

/* Button Styles */
button {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 15px; /* Space between form elements */
}

button:hover {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Form Row Alignment */
.form-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px; /* Space between form rows */
}

.form-label-left {
    text-align: left;
    font-weight: bold;
    flex: 0 0 25%; /* Label takes 25% width */
    max-width: 25%;
    padding-right: 10px; /* Space between label and input */
}

/* Input column styling */
.form-input-col {
    flex: 1; /* Input takes the remaining space */
}

/* Inputs Styling */
button, input, textarea, select {
    display: block;
    width: 100%;
    margin-top: 10px; /* Space between inputs */
}
