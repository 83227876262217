/* LoginPage.css */
.login-page-bg {
    background-color: #025e8c; /* Slightly lighter marine blue */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; /* Adjust padding for better spacing on small screens */
    overflow-y: auto; /* Adds scroll for overflowing content on small screens */
  }
  
  /* Custom styles for Card */
  .card {
    background-color: #013a63; /* Deep marine blue */
    border-radius: 10px;
  }
  
  /* Input Focus */
  input:focus {
    border-color: #ff6f61; /* Bright Light Coral */
    box-shadow: 0 0 5px rgba(255, 76, 76, 0.5); /* Coral shadow on focus */
  }
  
  /* Button Styles */
  button, .btn-primary {
    background-color: #ff6f61; /* Default to Bright Light Coral for Google */
    border-color: #ff6f61;
    color: white; /* White text for contrast */
    font-weight: bold;
  }
  
  button:hover, .btn-primary:hover {
    background-color: #012a4a; /* Darker shade of blue on hover */
    border-color: #012a4a;
  }
  
  /* Specific styling for Facebook button */
  .btn-facebook {
    background-color: #3b5998; /* Facebook Blue */
    border-color: #3b5998;
  }
  
  .btn-facebook:hover {
    background-color: #2d4373; /* Darker Facebook Blue on hover */
    border-color: #2d4373;
  }
  
  /* Centering the demo video */
  .demo-video-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .login-page-bg {
      padding: 5px; /* Reduce padding on smaller screens */
    }
  
    .card {
      width: 90%; /* Ensure card doesn't exceed screen width on mobile */
    }
  }
  
  @media (min-width: 769px) {
    .card {
      width: 400px; /* Set fixed width for larger screens */
    }
  }
  