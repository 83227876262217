/* Chat Window Container */
.chat-window {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Ensure the ChatWindow takes the available space */
    height: calc(100vh - 56px - 56px); /* Adjust based on your header and footer heights */
    margin-top: 10px;
    overflow: hidden; /* Prevents overflowing content from spilling out */
}

/* Header Styles */
.chat-window-header {
    background-color: #012a4a !important; /* Darker shade of blue */
    border-bottom: 1px solid white;
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 60px; /* Fixed height for the header */
}

/* Chat body should scroll when content overflows */
.chat-body {
    flex-grow: 1; /* Take up available space */
    overflow-y: auto; /* Enable scrolling when content overflows */
    padding: 10px;
    background-color: #025e8c; /* Slightly lighter marine blue */
    color: white;
}

/* Input Section */
.chat-input-container {
    background-color: #013a63; /* Deep marine blue */
    border-top: 1px solid white;
    padding: 10px;
    flex-shrink: 0; /* Prevent shrinking */
}

/* Message List Styles (optional, if you need to customize further) */
.message-list {
    list-style-type: none;
    padding: 0;
}

.message-list li {
    margin-bottom: 10px;
}

.message-list .bot-message {
    color: #ff6f61; /* Light coral for bot messages */
}

.message-list .user-message {
    color: white; /* White for user messages */
}

/* Assistant Typing Section */
.assistant-typing {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.assistant-typing span {
    margin-left: 5px;
}

/* Error Message */
.error-message {
    margin-top: 10px;
}
